<template>
	<div class="page-box" ref="content">
		<div class="page-box-con">
			<!-- <div class="main-top">
        <el-image fit="cover" :src="logoImg" class="index-logo"></el-image>
        <div class="heard-btn">
          <P>距离高考还剩:</P>
          <span id="timeShow"></span><P>天</P>
          <div
            class="full-icon"
            :style="`backgroundImage:url(${exitIcon})`"
            @click="exitAction"
          ></div>
        </div>
      </div>   -->
			<div class="main-right" ref="mainRight">
				<div class="right-con">
					<keep-alive>
						<router-view />
					</keep-alive>
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
import { fullScreen, exitScreen } from '@/utils/public.js';
import { mapGetters } from 'vuex';
export default {
	name: 'indexPage',
	computed: {
		...mapGetters({
			fullFlag: 'common/getFullScreen'
		})
	},
	data() {
		return {
			logoImg: require('@/assets/image/logo.png'),
			userIcon: require('@/assets/image/icon/userIcon.png'),
			fsHandle: require('@/assets/image/icon/fullScreen.png'),
			exitFull: require('@/assets/image/icon/exitFull.png'),
			backPage: require('@/assets/image/icon/backPage.png'),
			exitIcon: require('@/assets/image/exiticon.png'),
			menuData: [
				{
					text: '首页',
					iconGary: require('@/assets/image/icon/menu_icon0_0.png'),
					iconWhite: require('@/assets/image/icon/menu_icon0_1.png'),
					url: '/',
					status: true
				},
				{
					text: '图书馆',
					iconGary: require('@/assets/image/icon/menu_icon1_0.png'),
					iconWhite: require('@/assets/image/icon/menu_icon1_1.png'),
					url: '/library',
					status: false
				},
				{
					text: '错题本',
					iconGary: require('@/assets/image/icon/menu_icon2_0.png'),
					iconWhite: require('@/assets/image/icon/menu_icon2_1.png'),
					url: '/errorBook',
					status: false
				},
				{
					text: '学习中心',
					iconGary: require('@/assets/image/icon/menu_icon3_0.png'),
					iconWhite: require('@/assets/image/icon/menu_icon3_1.png'),
					url: '/userCenter',
					status: false
				}
			],
			scaleNum: 1,
			screenWidth: 1920,
			screenHeight: 1080,
			marginNum: 50,
			mainRightWidth: 0,
			
		};
	},
	watch: {},
	created() {
		let idx = this.$route.query.indexActive || 0;
		this.setActive(idx);
	},

	mounted() {
		this.$eventBus.$on('backIndex', () => {
			this.setActive(0);
		});
	},
	destroyed() {
		this.$eventBus.$off('backIndex');
	},
	methods: {
		fullScreen,
		exitScreen,
		
		exitAction() {
			this.$confirm();

			let that = this;
			that.$confirm('确定要退出吗？', {
				showClose: false,
				callback: function (val) {
					if (val == 'confirm') {
						that.$api.userLogout().then((res) => {
							if (res.code == 0) {
								window.localStorage.setItem('token', '');
								window.location.reload();
							}
						});
					}
				}
			});
		},
		fullScreenAction() {
			if (this.fullFlag) {
				this.exitScreen(() => {
					this.$store.commit('common/setFullScreen', false);
				});
			} else {
				this.fullScreen(() => {
					this.$store.commit('common/setFullScreen', true);
				});
			}
		},
		selectMenu(item, idx) {
			this.setActive(idx);
			this.$router.replace({
				path: item.url,
				query: {
					indexActive: idx
				}
			});
			this.$eventBus.$emit('changeIndeMenu');
		},
		setActive(idx) {
			this.menuData.forEach((item, index) => {
				item.status = false;
				if (index == idx) {
					item.status = true;
				}
			});
		},
		getDiffDay(date_1, date_2) {
			let totalDays, diffDate;
			let myDate_1 = Date.parse(date_1);
			let myDate_2 = Date.parse(date_2);
			diffDate = Math.abs(myDate_1 - myDate_2);
			totalDays = Math.floor(diffDate / (1000 * 3600 * 24));
			return totalDays;
		},
		Countdown() {
			window.onload = function () {
				var now = new Date();
				var Year = now.getFullYear();
				var timedate = new Date(Year + 1, 5, 7); //自定义结束时间  '
				var date = timedate.getTime() - now.getTime(); //得出的为毫秒
				var time = Math.ceil(date / (1000 * 60 * 60 * 24)); //1000 * 60 * 60 * 24一天的秒数
				if (time > 0) {
					document.getElementById('timeShow').innerHTML = time;
				}
			};
		}
	}
};
</script>

<style lang="scss" scoped>
.heard-btn {
	position: fixed;
	right: 0;
	top: 0;
	padding: 0.5rem;
	z-index: 1000;
	display: flex;
	align-items: center;
	transform-origin: right top;
	font-size: 0.25rem;
	p {
		font-family: FZDHTJW--GB1-0;
		font-size: 0.25rem;
		color: #fff;
		letter-spacing: 0.05rem;
	}
	span {
		color: yellow;
		letter-spacing: 0.05rem;
	}
	.full-icon {
		width: 0.64rem;
		height: 0.64rem;
		margin-left: 0.1rem;
		background-size: cover;
		&:hover {
			opacity: 0.8;
		}
	}
}
.page-box {
	display: flex;
	justify-content: flex-start;
	transform-origin: 0 0;
	.page-box-con {
		width: 100vw;
		height: 100vh;
	}
	.main-top {
		width: 100%;
		.index-logo {
			width: 3rem;
			height: 0.65rem;
			min-height: 0.65rem;
			margin: 0.45rem 0rem 0.2rem 0.67rem;
		}
	}
	.main-left {
		display: flex;
		flex-direction: column;
		width: 2.54rem;
		min-width: 2.54rem;
		margin-right: 0.32rem;
		transform-origin: left top;
		color: #fff;
		margin: 0.5rem 0.32rem 0.5rem 0.5rem;
		.index-logo {
			width: 5.5rem;
			height: 0.65rem;
			min-height: 0.65rem;
			margin-bottom: 0.67rem;
		}
	}
	.main-right {
		flex: 1;
		display: flex;
		flex-direction: column;
		transform-origin: left top;
		// overflow: hidden;
		//margin: 0rem 0.5rem 0rem 0.5rem;
		.right-con {
			flex: 1;
			background: #ffffff;
			border-radius: 0.2rem;
			// overflow: hidden;
		}
	}
}
</style>
