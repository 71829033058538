<template>
  <div class="scroll-box" :id="domId" :ref="domId">
    <slot></slot>
  </div>
</template>

<script>
import BetterScroll from "better-scroll";
import ScrollBar from "@better-scroll/scroll-bar";
BetterScroll.use(ScrollBar);
export default {
  name: "scrollBox",
  props: {
    domId: {
      type: String,
      default: "",
    },
    scrollX: {
      type: Boolean,
      default: false,
    },
    scrollY: {
      type: Boolean,
      default: true,
    },
    startY: {
      type: [Number, String],
      default: 0,
    },
    preventDefaultDoor:{
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      scrollObj: {},
    };
  },
  created() {},
  mounted() {
    setTimeout(() => {
      this.initBscroll();
      this.scrollObj[this.domId].on("scrollEnd", (position) => {
        // let scrollDomTop = this.$store.getters["common/getScrollDomTop"];
        // scrollDomTop[this.domId] = position;
        // this.$store.commit("common/setScrollDomTop", 0);
      });
    }, 100);
    this.$eventBus.$on("scrollScrollTo", (val) => {
      if (this.scrollObj[val.dom]) {
        this.scrollObj[val.dom].scrollTo(val.x, val.y);
      }
    });
    this.$eventBus.$on("refreshScroll", (val) => {
      if (this.scrollObj[val.dom]) {
        this.scrollObj[val.dom].refresh();
      }
    });
    this.$eventBus.$on("backTop", (val) => {
      if (this.scrollObj[val.dom]) {
        this.scrollObj[val.dom].scrollTo(0, 0);
      }
    });
    window.addEventListener("resize", () => {
      setTimeout(() => {
        this.scrollObj[this.domId].refresh();
        this.scrollObj[this.domId].scrollTo(0, 0);
      }, 100);
    });
  },
  destroyed() {
    //this.$eventBus.$off("refreshScroll");
  },
  methods: {
    initBscroll() {
      let that = this;
      if (that.domId.length) {
        that.scrollObj[that.domId] = new BetterScroll(that.$refs[that.domId], {
          scrollX: that.scrollX,
          scrollY: that.scrollY,
          startY: that.startY,
          click: true,
          mouseWheel: true,
          bounce: false,
          swipeBounceTime: 0,
          bounceTime: 0,
          preventDefault:that.preventDefaultDoor,
          scrollbar: {
            fade: true,
            interactive: true,
          },
        });
        //let scrollDomTop = this.$store.getters["common/getScrollDomTop"];
        // that.$nextTick(() => {
        //   that.scrollObj[that.domId].scrollTo(
        //     0,
        //     scrollDomTop[that.domId]?.y || 0
        //   );
        // });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.scroll-box {
  position: relative;
  width: 100%;
  height: 100%;
  padding-right: 0.3rem;
  overflow: hidden;
}
.overflowY {
  overflow-y: hidden;
}
.overflowX {
  overflow-x: hidden;
}
</style>
