<template>
  <div id="app">
    <header-top v-if="$route.path.indexOf('login') === -1 && $route.path.indexOf('sigin') === -1 && $route.path.indexOf('userInfs') === -1"/>
    <keep-alive include="indexPage">
      <router-view />
    </keep-alive>
 <!--   <div class="webInfs" v-if="$route.path.indexOf('login') === -1">
      <a href="https://beian.miit.gov.cn/" target="_blank"
        >京ICP备2022024380号</a
      >
      Copyright ©2021 京点未来教育研究院 版权所有
    </div> -->
      
    <audio
      :src="errorVoice"
      controlslist="nodownload"
      controls="controls"
      ref="errorAudio"
      class="voice-dom"
    ></audio>
    <audio
      :src="properVoice"
      controlslist="nodownload"
      controls="controls"
      ref="properAudio"
      class="voice-dom"
    ></audio>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import headerTop from "./components/headerTop.vue";
import { imageToBase64 } from '@/utils/public.js'
export default {
  name: "App",
  computed: {
    ...mapGetters({
      isFullScreen: "common/getFullScreen",
    }),
  },
    // $route: function (val) {},
	watch: {
		$route: function (val) {
			this.currentPath = this.$route.path || '/';
			this.active = this.$route.query.active || 0;
			if (val.path == '/login') {
				window.document.title = '高考版登录';
			} else {
				window.document.title = '高分考鉴';
			}
		}
	},
  data() {
    return {
      globalBg: "",
      fullFlag: false,
      errorVoice: require("@/assets/audio/error.mp3"),
      properVoice: require("@/assets/audio/proper.wav"),
      errorAudio: null,
      properAudio: null,
    };
  },
  async created() {
    //this.globalBg = await imageToBase64(require('@/assets/image/globalbackground.png'))
    // console.log(this.$route.path)
    // var is360 = _mime("type", "application/vnd.chromium.remoting-viewer");
    // if (isChrome() && !is360) {
    //   this.$message({
    //     type: "warning",
    //     message: "为了有更好的使用体验，请使用360浏览器！",
    //     duration:0,
    //     showClose:true
    //   });
    // }

    // rem
    // eslint-disable-next-line
    // (function(doc, win) {
    //   let docEl = doc.documentElement,
    //   resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
    //   recalc = function() {
    //     let clientWidth = docEl.clientWidth;
    //     console.log(clientWidth)
    //     if (!clientWidth) return;
    //     docEl.style.fontSize = 100 * (clientWidth / 1920) + 'px';
    //   }
    //   if (!doc.addEventListener) return;
    //   win.addEventListener(resizeEvt, recalc, false);
    //   doc.addEventListener('DOMContentLoaded', recalc, false);
    // })(document, window)

    function isChrome() {
      var ua = navigator.userAgent.toLowerCase();

      return ua.indexOf("chrome") > 1;
    }
    function _mime(option, value) {
      var mimeTypes = navigator.mimeTypes;
      for (var mt in mimeTypes) {
        if (mimeTypes[mt][option] == value) {
          return true;
        }
      }
      return false;
    }
  },
  mounted() {
    this.errorAudio = this.$refs["errorAudio"];
    this.properAudio = this.$refs["properAudio"];

    this.$eventBus.$on("playError", () => {
      this.errorAudio.currentTime=0;
      this.properAudio.currentTime=0;
      this.errorAudio.play();
    });
    this.$eventBus.$on("playProper", () => {
      this.errorAudio.currentTime=0;
      this.properAudio.currentTime=0;
      this.properAudio.play();
    });
    window.addEventListener("resize", () => {
      this.fullFlag = !this.fullFlag;
    });

    window.addEventListener("dragstart", (e) => {
      let localName = e.target.localName;
      if (localName == "img") {
        e.preventDefault();
      }
    });
  },
  destroyed() {},
  methods: {},
  components: {
    headerTop
  }
};
</script>

<style lang="scss">
@import "assets/css/iconfont.css";
@import "assets/css/baseStyle.scss";
.el-button--primary{
  background-color: #0559A1!important;
}
.el-message--success{
  background-color: #fff!important;
  border-color: #0559A1!important;
  
}
.el-message .el-icon-success{
  color: #0559A1!important;
}
.el-message__content{
    color: #0559A1!important;
    
  }
.el-message-box__message p {
  font-size: 0.2rem;
}
.el-message-box__btns {
  .el-button{
    font-size: 0.16rem;
    font-weight: normal;
    
  }
}

.el-message__icon {
  font-size: 0.4rem;
}
.el-message-box__message {
  font-size: 0.16rem;
  padding: 0.2rem 0;
  text-align: center;
}
</style>
<style lang="scss" scoped>
.webInfs {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0.01rem;
  font-size: 14px;
  //background-color: #FFFFFF;
  color: #909090;
  text-align: center;
  height: 0.5rem;
  line-height: 0.5rem;
  a {
    color: #909090;
    text-decoration: none;
  }
}
.voice-dom {
  position: fixed;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}
#app {
  // display: flex;
  // justify-content: center;
  // align-items: center;
  width: 100vw;
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  user-select: text;
  transform-origin: center;
}
</style>
