import Vue from 'vue'
import Vuex from 'vuex'

import common from './modules/common'
import VuexPersistence from 'vuex-persist'
Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer(val) {
    return {
      common: {
        rememberAcount:val.common.rememberAcount,
        acountInfs:val.common.acountInfs,
        userInfs:val.common.userInfs,
        moudleNav:val.common.moudleNav,
        channelMenuData:val.common.channelMenuData,
        classMask:val.common.classMask
      }

    }
  }
})
const store = new Vuex.Store({
  strict: false,
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    common,
  },
  plugins: [vuexLocal.plugin]

})

export default store;
