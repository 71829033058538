<template>
	<div class="main-top">
		<!-- <el-image fit="cover" :src="logoImg" class="index-logo"></el-image> -->
		<img :src="logoImg" alt="" class="index-logo" />
		<div class="heard-btn">
			<P>距离高考还剩:</P>
			<span id="timeShow">{{ getUserInfs.days }}</span>
			<P>天</P>
			<!--  <span id="timeShow"></span><P>天</P> -->
			<div
				class="full-icon"
				:style="`backgroundImage:url(${fullFlag ? exitFull : fsHandle})`"
				@click="fullScreenAction"
			></div>
			<div
				class="full-icon"
				:style="`backgroundImage:url(${fullFlag ? userIcon : userIcon})`"
				@click="$router.push('/userInfs')"
			></div>
			<!-- <div
				v-show="$route.path.indexOf('zhiShiQuanJie') < 0"
				class="full-icon"
				:style="`backgroundImage:url(${exitIcon})`"
				@click="exitAction"
			></div>
			
			<div
				v-show="$route.path.indexOf('zhiShiQuanJie') > -1"
				class="full-icon"
				:style="`backgroundImage:url(${backPage})`"
				@click="$router.push('/')"
			></div> -->
			<div
				v-if="path"
				class="full-icon"
				:style="`backgroundImage:url(${exitIcon})`"
				@click="exitAction"
			></div>
			
			<div
				v-else
				class="full-icon"
				:style="`backgroundImage:url(${backPage})`"
				@click="goPath"
			></div>
			<!-- <div
        v-show="$route.path.indexOf('zhiShiQuanJie') > -1"
        class="full-icon"
        :style="`backgroundImage:url(${homePage})`"
        @click="$router.push('/')"
      ></div> -->
		</div>
	</div>
</template>
<script>
import { fullScreen, exitScreen } from '@/utils/public.js';
import moment from 'moment';
import { mapGetters } from 'vuex';
export default {
	created() {
		// this.daysDiff()
	},

	data() {
		return {
			logoImg: require('@/assets/image/logoT.png'),
			exitIcon: require('@/assets/image/exiticon.png'),
			backPage: require('@/assets/image/back.png'),
			homePage: require('@/assets/image/homeIcon.png'),
			fsHandle: require('@/assets/image/fullscreen.png'),
			exitFull: require('@/assets/image/fullscreen.png'),
			userIcon: require('@/assets/image/userIcon.png'),
			fullFlag: false,
			path:null,
			pathstring:'',
			timeText:0,
			getUserInfs:this.$store.getters['common/getUserInfs']
		};
	},
	watch: {
		$route: function (val) {
			this.pathstring = val.path;
			if (val.path == '/') {
				this.path= true;
			}else{
				this.path= false;
			}
		}
	},
	mounted() {
		window.addEventListener('resize', () => {
			this.fullFlag = !this.fullFlag;
		});
	},
	methods: {
		fullScreen,
		exitScreen,
		fullScreenAction() {
			if (this.fullFlag) {
				this.exitScreen(() => {
					this.$store.commit('common/setFullScreen', false);
				});
			} else {
				this.fullScreen(() => {
					this.$store.commit('common/setFullScreen', true);
				});
			}
		},
		exitAction() {
			this.$confirm();

			let that = this;
			that.$confirm('确定要退出吗？', {
				showClose: false,
				callback: function (val) {
					if (val == 'confirm') {
						that.$api.userLogout().then((res) => {
							if (res.code == 0) {
								window.localStorage.setItem('token', '');
								window.location.reload();
							}
						});
					}
				}
			});
		},
		daysDiff(){
			let time = new Date();
			let FullYear= time.getFullYear();
			let Month= time.getMonth()+1;
			let year= time.getDate();
			let date = new Date(`${FullYear}-${Month}-${year}`)
			let date2 = new Date(`${FullYear+1}-06-07`)
			this.timeText = Math.ceil(Math.abs(date - date2) / 86400000)
		},
		goPath(){
			let pathstring = '';
			console.log(this.$route.query.coursetypeid)
			if(this.pathstring == '/zhenTi'){
				pathstring =''
			}else if(this.pathstring == '/zhenTiContent'){
				pathstring ='zhenTi'
			}else if(this.pathstring == '/zhiShiQuanJie'&&this.$route.query.iskayt==1){
				pathstring ='kqyt'
			}
			this.$router.push({
				path: `/${pathstring}`,
				query:{
					coursetypeid:this.$route.query.coursetypeid||this.$route.query.subject_id,
					papeid:this.$route.query.papeid,
				}
			})
		}	
	},
	computed: {
		...mapGetters({
			isFullScreen: 'common/getFullScreen'
		}),
		
	}
};
</script>
<style lang="scss" scoped>
.main-top {
	width: 100%;
	height: 86px;
	background-color: #05549a;
	display: flex;
	align-items: center;
	.index-logo {
		display: inline-block;
		// padding: 0.14rem 0px 25px 40px;
		width: 2.2rem;
		height: auto;
		margin-left: 0.4rem;
		margin-top: 0.08rem;
	}
	.heard-btn {
		position: fixed;
		right: 0;
		top: 0;
		// padding: 0.5rem;
		padding: 20px 50px 0 0;
		z-index: 1000;
		display: flex;
		align-items: center;
		transform-origin: right top;
		font-size: 0.25rem;
		p {
			font-family: FZDHTJW--GB1-0;
			font-size: 0.25rem;
			color: #fff;
		}
		span {
			color: yellow;
		}
		.full-icon {
			cursor: pointer;
			width: 0.4rem;
			height: 0.4rem;
			margin-left: 0.1rem;
			background-size: cover;
			&:hover {
				opacity: 0.8;
			}
		}
	}
}
</style>
