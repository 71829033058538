const globalMethods = {
  methods: {
    addHistory() {
      let query = this.$route.query;
      console.log(query)
      this.$api
        .addHistory({
          course_id: query.bookId,
          subject_id: query.childId || query.channelId || query.classId ,
          learningrecord: {
            path: this.$route.path,
            query: { ...query },
            curseimg: this.$store.getters["common/getClassMask"],
            menutype: window.sessionStorage.getItem("menutype"),
          },
        })
        .then((res) => {
          console.log(res);
        });
    },
  },
};

export default globalMethods;
